<template>
    <tab-selfservice v-if="tab.type === 'selfservice'" :options="tab.options" ref="content"></tab-selfservice>
    <tab-resources v-else-if="tab.type === 'resources'" :options="tab.options" :resourceType="tab.resourceType" ref="content"></tab-resources>
    <tab-single-resource v-else-if="tab.type === 'single-resource'" :options="tab.options" :resourceType="tab.resourceType" :resourceID="tab.resourceID" ref="content"></tab-single-resource>
    <tab-logout v-else-if="tab.type === 'logout'" :options="tab.options" ref="content"></tab-logout>
    <div v-else ref="content">An invalid tab type is specified in the configuration.</div>
</template>

<script>
    import TabSelfservice from "./tabs/selfservice.vue";
    import TabResources from "./tabs/resources.vue";
    import TabSingleResource from "./tabs/single-resource.vue";
    import TabLogout from "./tabs/logout.vue";

    export default {
        props: ["tab"],
        data() {
            this.$on("show", (...args) => this.$refs.content.$emit("show"));
            this.$on("hide", (...args) => this.$refs.content.$emit("hide"));
            return {};
        },
        components: {
            "tab-selfservice": TabSelfservice,
            "tab-resources": TabResources,
            "tab-single-resource": TabSingleResource,
            "tab-logout": TabLogout,
        },
    }
</script>