<template>
    <div class="field">
        <label class="label" :for="'field.' + schema.name" v-html="schema.options.title[app.language] || schema.options.title['']"></label>
        <div class="control" :class="{'has-icons-left': schema.options.icon, 'select': schema.options.choices}">
            <select v-if="schema.options.choices"
                    :value="value" @change="$emit('input', $event.target.value)"
                    ref="input" :id="'field.' + schema.name"
                    :disabled="app.loading || !schema.writable" :readonly="schema.options.readonly"
                    >
                <option v-for="o in schema.options.choices" :value="o">{{ o }}</option>
            </select>
            <textarea v-else-if="schema.options.long"
                   class="input" style="min-width: 100%; min-height: 5.5em;"
                   :autocomplete="schema.options.autocomplete"
                   :autocorrect="schema.options.spellcheck ? undefined : 'off'" :spellcheck="schema.options.spellcheck ? undefined : 'false'"
                   :value="value" @input="$emit('input', $event.target.value)"
                   ref="input" :id="'field.' + schema.name"
                   :disabled="app.loading || !schema.writable" :readonly="schema.options.readonly"
                   :class="{'is-danger': requiredError || emailError}"></textarea>
            <input v-else
                   class="input" :type="schema.options.type || schema.type"
                   :autocomplete="schema.options.autocomplete"
                   :autocorrect="schema.options.spellcheck ? undefined : 'off'" :spellcheck="schema.options.spellcheck ? undefined : 'false'"
                   :value="value" @input="$emit('input', $event.target.value)"
                   ref="input" :id="'field.' + schema.name"
                   :disabled="app.loading || !schema.writable" :readonly="schema.options.readonly"
                   :class="{'is-danger': requiredError || emailError}">
            <span class="icon is-small is-left" v-if="schema.options.icon"><i :class="schema.options.icon"></i></span>
        </div>
        <p class="help">
            <span v-if="requiredError" class="has-text-danger" v-html="t('resource.required')"></span>
            <span v-else-if="emailError" class="has-text-danger" v-html="t('resource.invalidEmail')"></span>
            <slot></slot>
        </p>
    </div>
</template>

<script>
    export default {
        props: ["schema", "value", "error"],
        data() {
            this.$nextTick(() => this.emailError = this.updateEmailError());
            return {
                app: window.peopledApp,
                emailError: null,
            };
        },
        methods: {
            t(key, ...args) {
                return this.app.t(key, ...args);
            },
            updateEmailError(value) {
                if (!value) value = this.value;
                if (!this.schema.writable || this.schema.type !== "email") return false;
                if (Array.isArray(value)) value = value[0] || "";
                if (!this.$refs.input) this.$nextTick(() => this.emailError = this.updateEmailError());
                return this.$refs.input && (this.$refs.input.validationMessage || !(value.indexOf("@") > 0 && value.indexOf("@") < value.lastIndexOf(".")));
            },
        },
        computed: {
            requiredError() {
                if (!this.schema.writable) return false;
                return this.schema.options.required && !this.value.trim();
            },
            anyError() {
                if (this.requiredError) return this.t('resource.required');
                else if (this.emailError) return this.t('resource.invalidEmail');
                else return null;
            },
        },
        watch: {
            value(v) {
                this.emailError = this.updateEmailError(v);
            },
            anyError(v) {
                this.$emit('update:error', v);
            }
        }
    }
</script>
