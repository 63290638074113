<template>
    <div>
        <h3 class="subtitle" v-html="t('selfservice.apps')"></h3>
        <div class="selfservice-apps">
            <a v-for="appLink in app.schema.apps" :title="appLink.description[app.language] || appLink.description['']" :href="appLink.url" target="_blank" class="button is-light">
                <span class="icon"><i v-if="appLink.icon.indexOf('.') < 0" :class="[appLink.icon]" aria-hidden="true"></i><img v-else :src="appLink.icon"></span>
                <span v-html="appLink.name[app.language] || appLink.name['']"></span>
            </a>
        </div>

        <br>

        <div v-if="profile && Object.keys(profile).length">
            <hr>
            <h3 class="subtitle" v-html="t('selfservice.profile')"></h3>

            <form @submit="submit">
                <peopled-field
                        v-for="field in app.schema.resources[app.schema.userResource].filter(x => app.can(x.readViews, 'selfservice') || app.can(x.writeViews, 'selfservice'))"
                        :key="field.name"
                        :schema="{
                            ...field,
                            check: app.can(field.writeViews, 'selfservice') ? app.can(field.readViews) ? true : 'if-set' : false,
                            writable: app.can(field.writeViews, 'selfservice'),
                        }"
                        v-model="profile[field.name]"
                        :error.sync="errors[field.name]"
                ></peopled-field>

                <hr>
                <div class="field">
                    <div class="control">
                        <button type="submit" class="button is-link" :disabled="disable" :class="{'is-loading': app.loading}" style="margin-bottom: 0" v-html='t("selfservice.save")'></button>
                    </div>
                </div>
            </form>

            <hr>
            <h3 class="subtitle" v-html="t('selfservice.delete')"></h3>
            <p v-html="t('selfservice.deleteInformation', {mail:app.schema.links.mail})"></p>
        </div>
        <div v-else-if="profile !== null" class="is-flex" style="align-items: center; justify-content: center; height: 128px">
            <i class="ri-loader-4-fill ri-3x is-spinning"></i>
        </div>
    </div>
</template>

<script>
    import Field from "../field.vue";
    export default {
        data() {
            this.$nextTick(async () => {
                let res, body;
                try {
                    res = await fetch(window.peopledEndpoint + "/resource/" + encodeURIComponent(this.app.schema.userResource) + "/" + encodeURIComponent(this.app.user.username), {
                        method: "GET",
                        headers: {
                            "Accept": "application/json",
                            "Authorization": window.peopledToken,
                        },
                    });
                    body = await res.json();
                } catch (err) {
                    console.error(err);
                    this.profile = null;
                    this.app.dialog = { type: "danger", context: "networkError" };
                }
                if (!res.ok) {
                    setTimeout(() => {
                        this.app.dialog = { type: "danger", "context": "selfservice" + res.status };
                        this.profile = null;
                    }, 150);
                } else {
                    this.readParameters = Object.keys(body);
                    for (let field of this.app.schema.resources[this.app.schema.userResource]) {
                        if (typeof body[field.name] === "undefined") body[field.name] = "";
                    }
                    this.profile = body;
                }
            });
            return {
                app: window.peopledApp,
                profile: {},
                errors: {},
                readParameters: [],
            };
        },
        components: {
            "peopled-field": Field,
        },
        methods: {
            t(key, ...args) {
                return this.app.t(key, ...args);
            },
            async submit() {
                window.event.preventDefault();
                if (this.app.loading || this.disable) return;
                this.app.dialog = null;
                this.app.loading = true;
                let res, body;
                try {
                    let request = {};
                    for (let f of this.app.schema.resources[this.app.schema.userResource]) {
                        if (!this.app.can(f.writeViews, "selfservice")) continue;
                        if (this.readParameters.indexOf(f.name) >= 0) request[f.name] = this.profile[f.name];
                        else if (this.profile[f.name] && (this.profile[f.name].trim ? this.profile[f.name].trim().length : this.profile[f.name].length)) request[f.name] = this.profile[f.name];
                    }
                    res = await fetch(window.peopledEndpoint + "/resource/" + encodeURIComponent(this.app.schema.userResource) + "/" + encodeURIComponent(this.app.user.username), {
                        method: "PUT",
                        headers: {
                            "Accept": "application/json",
                            "Content-Type": "application/json",
                            "Authorization": window.peopledToken,
                        },
                        body: JSON.stringify(request),
                    });
                    body = await res.json();
                } catch (err) {
                    console.error(err);
                    this.app.dialog = { type: "danger", context: "networkError" };
                    this.app.loading = false;
                    return;
                }
                if (!res.ok) {
                    setTimeout(() => {
                        this.app.dialog = { type: "danger", "context": "selfservice" + res.status };
                        this.app.loading = false;
                    }, 150);
                    return;
                }
                this.app.dialog = { type: "success", context: "selfserviceSave" };
                this.app.loading = false;
                for (let f of this.app.schema.resources[this.app.schema.userResource]) if (f.type == "password") this.profile[f] = "";
                this.app.top();
            }
        },
        computed: {
            disable() {
                return Object.values(this.errors).filter(x => x).length > 0;
            }
        }
    }
</script>

<style>
    .selfservice-apps {
        display: flex; flex-wrap: wrap;
    }
    .selfservice-apps .button {
        flex: none;
        display: flex; flex-direction: column; align-items: center; justify-content: center;
        font-size: 0.9rem;
        width: 8.5em; height: 8.5em; margin-bottom: 0.5em; margin-right: 0.5em;
        white-space: normal;
    }
    .selfservice-apps .button>span:not(.icon) {
        flex-basis: 40%;
        line-height: 1.2;
    }
    .selfservice-apps .icon {
        font-size: 2.5em;
        width: 2.5em;
        height: 2.5em;
        margin-left: 0 !important;
        margin-right: 0 !important;
        padding-top: 0.35em;
        flex-basis: 60%;
    }
    .selfservice-apps .icon img {
        width: 1em; height: 1em;
        margin: 0.25em 0;
    }


    @media screen and (max-width: 40rem) {
        .selfservice-apps {
            display: grid;
            grid-gap: 0.5em;
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
        .selfservice-apps .button {
            font-size: 2.5vw;
            margin: 0; width: auto;
        }
    }
    @media screen and (max-width: 30rem) {
        .selfservice-apps {
            grid-template-columns: 1fr 1fr 1fr;
        }
        .selfservice-apps .button {
            font-size: 3.3vw;
        }
    }
</style>