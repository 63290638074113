<template>
    <field-text v-if="schema.type == 'text'" :schema="schema" :error="error" @update:error="$emit('update:error', $event)" :value="value" @input="$emit('input', $event)"><slot></slot></field-text>
    <field-email v-else-if="schema.type == 'email'" :schema="schema" :error="error" @update:error="$emit('update:error', $event)" :value="value" @input="$emit('input', $event)"><slot></slot></field-email>
    <field-password v-else-if="schema.type == 'password'" :schema="schema" :error="error" @update:error="$emit('update:error', $event)" :value="value" @input="$emit('input', $event)"><slot></slot></field-password>
    <field-picture v-else-if="schema.type == 'picture'" :schema="schema" :error="error" @update:error="$emit('update:error', $event)" :value="value" @input="$emit('input', $event)"><slot></slot></field-picture>
    <h3 class="subtitle" v-else-if="schema.type == 'separator' && app.can(schema.readViews, 'selfservice')" v-html="'<hr>' + (schema.options.title ? (schema.options.title[app.language] || schema.options.title['']) : '')"></h3>
    <div hidden v-else></div><!-- unknown field, ignore -->
</template>

<script>
    import FieldText from "./fields/text.vue";
    import FieldPassword from "./fields/password.vue";
    import FieldPicture from "./fields/picture.vue";
    export default {
        props: ["schema", "value", "error"],
        data() {
            return {app: window.peopledApp};
        },
        components: {
            "field-text": FieldText,
            "field-email": FieldText,
            "field-password": FieldPassword,
            //"field-memberOf": FieldMemberOf,
            "field-picture": FieldPicture,
        },
    }
</script>
