<template>
    <div class="field">
        <label class="label" :for="'field.' + schema.name" v-html="schema.options.title[app.language] || schema.options.title['']"></label>
        <div class="control" :class="{'has-icons-left': schema.options.icon}">
            <input class="input" type="password"
                   :value="value" @input="$emit('input', $event.target.value)"
                   ref="input" :id="'field.' + schema.name"
                   :disabled="app.loading || !schema.writable" :readonly="schema.options.readonly"
                   :class="{'is-danger': passwordError}">
            <span class="icon is-small is-left" v-if="schema.options.icon"><i :class="schema.options.icon"></i></span>
        </div>
        <p class="help">
            <span v-if="passwordError" class="has-text-danger" v-html="passwordError"></span>
            <slot></slot>
        </p>
    </div>
</template>

<script>
    const yxcvbn = require("yxcvbn");
    const zxcvbn = require("zxcvbn");
    const passwordRule = /[a-zA-Z] *[^a-zA-Z ]|[^a-zA-Z ] *[a-zA-Z]/;
    export default {
        props: ["schema", "value", "error"],
        data() {
            return {app: window.peopledApp}
        },
        methods: {
            t(key, ...args) {
                return this.app.t(key, ...args);
            },
        },
        computed: {
            passwordError() {
                if (!this.schema.check || (this.schema.check === "if-set" && !this.value)) return null;
                if (this.value.length < 8 || !this.value.match(passwordRule)) return this.t('login.passwordStatus.invalid');
                const result = (this.app.language === "de" ? yxcvbn : zxcvbn)(this.value);
                if (result.feedback.warning) return result.feedback.warning;
                if (result.score < 3) return this.t('login.passwordStatus.unsafe');
                return null;
            }
        },
        watch: {
            passwordError(v) {
                this.$emit('update:error', v);
            }
        }
    }
</script>
