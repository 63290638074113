<template>
        <form @submit="submit">
            <div class="field" v-if="app.token === null">
                <label class="label" for="username" v-html="t('login.usernameOrEmail')"></label>
                <div class="control has-icons-left">
                    <input class="input" type="text" autocomplete="nickname" autocorrect="off" spellcheck="false" v-model="username" ref="username" id="username" :disabled="app.loading">
                    <span class="icon is-small is-left"><i class="ri-user-fill"></i></span>
                </div>
            </div>
            <div class="field" v-else>
                <label class="label" for="password" v-html="t('login.password')"></label>
                <div class="control has-icons-left">
                    <input class="input" type="password" v-model="password" ref="password" id="password" :disabled="app.loading" :class="{'is-danger': password && passwordIsInvalid}">
                    <span class="icon is-small is-left"><i class="ri-lock-password-fill"></i></span>
                </div>
                <p class="help" :class="{'is-danger': password}" v-if="passwordIsInvalid" v-html="t('login.passwordStatus.invalid')"></p>
            </div>

            <div class="field">
                <div class="control">
                    <button type="submit" class="button is-link" v-if="app.token === null" :disabled="disable" :class="{'is-loading': app.loading}" v-html="t('login.submit.reset')"></button>
                    <button type="submit" class="button is-link" v-else :disabled="disable" :class="{'is-loading': app.loading}" v-html="t('login.submit.confirm')"></button>
                    <button type="button" class="button" :disabled="app.loading" onclick="return false" @click="app.goto('login')" v-html="t('login.goto.login')"></button>
                </div>
            </div>
        </form>
</template>

<script>
    const passwordRule = /[a-zA-Z][^a-zA-Z ]|[^a-zA-Z ][a-zA-Z]/;
    export default {
        data() {
            return {
                app: window.peopledApp,
                username: "",
                password: "",
            }
        },
        methods: {
            t(key, ...args) {
                return this.app.t(key, ...args)
            },

            submit() {
                window.event.preventDefault();
                if (this.app.loading || this.disable) return;
                this.app.dialog = null;
                this.app.loading = true;
                if (this.app.token === null) this.request();
                else this.confirm();
            },

            async confirm() {
                let res, body;
                try {
                    res = await fetch(window.peopledEndpoint + "/recovery/" + this.app.token, {
                        method: "POST",
                        headers: {
                            "Accept": "application/json",
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            "password": this.password,
                        }),
                    });
                    body = await res.json();
                } catch (err) {
                    console.error(err);
                    this.app.dialog = { type: "danger", context: "networkError" };
                    this.app.top();
                    return;
                }
                if (!res.ok) {
                    setTimeout(() => {
                        this.app.dialog = { type: "danger", context: "recovery" + res.status };
                        this.app.loading = false;
                        this.$nextTick(() => (this.$refs.username || this.$refs.password).select());
                        this.app.top();
                    }, 150);
                } else {
                    this.app.goto("login");
                    history.replaceState(null, "", " ");
                    window.parent.postMessage({ peopledRemoveHash: true }, "*");
                    if (body.success) this.app.dialog = { type: "success", context: "recoverySuccess" };
                    else this.app.dialog = { type : "warning", message: body.error };
                }
            },

            async request() {
                let res, body;
                try {
                    res = await fetch(window.peopledEndpoint + "/recovery", {
                        method: "POST",
                        headers: {
                            "Accept": "application/json",
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            "username": this.username,
                        }),
                    });
                    body = await res.json();
                } catch (err) {
                    console.error(err);
                    this.app.dialog = { type: "danger", context: "networkError" };
                    this.app.top();
                    return;
                }
                if (!res.ok) {
                    setTimeout(() => {
                        this.app.dialog = { type: "danger", "context": "recovery" + res.status };
                        this.app.loading = false;
                        this.app.top();
                    }, 150);
                } else {
                    this.app.goto("login");
                    history.replaceState(null, "", " ");
                    window.parent.postMessage({ peopledRemoveHash: true }, "*");
                    this.app.dialog = { type: "warning", context: "recoveryRequest" };
                }
            },

        },
        computed: {
            passwordIsInvalid() {
                return this.password.length < 8 || !this.password.match(passwordRule);
            },
            disable() {
                return (this.app.token === null && this.username == "") || (this.app.token !== null && (this.passwordIsInvalid || this.password == "")) ;
            },
        },
    }
</script>