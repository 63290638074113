<template>
    <div></div>
</template>

<script>
    export default {
        data() {
            this.$on("show", async () => {
                if (!peopledToken || this.app.user === null) {
                    this.app.goto("login");
                    return;
                }
                this.app.loading = "full";
                let res, body;
                try {
                    res = await fetch(window.peopledEndpoint + "/authentication", {
                        method: "DELETE",
                        headers: {Authorization: window.peopledToken},
                    });
                    body = await res.json();
                } catch (err) {
                    console.error(err);
                    this.app.loading = false;
                    this.app.dialog = {type: "danger", context: "networkError"};
                    return;
                }
                if (!res.ok || !body.success) {
                    console.error(res.status + " " + res.statusText + ":", body);
                    this.app.loading = false;
                    this.app.dialog = {type: "danger", context: "networkError"};
                    return;
                }
                window.peopledToken = "";
                this.app.goto("login");
                this.app.dialog = {
                    type: "success",
                    context: "logout",
                };
            });
            return {app: window.peopledApp};
        },
    }
</script>
