<template>
	<form @submit="submit">
		<div class="field">
			<label class="label" for="username" v-html="t('login.usernameOrEmail')"></label>
			<div class="control has-icons-left">
				<input class="input" type="text" autocomplete="nickname" autocorrect="off" spellcheck="false" v-model="username" ref="username" id="username" :disabled="app.loading">
				<span class="icon is-small is-left"><i class="ri-user-fill"></i></span>
			</div>
		</div>

		<div class="field">
			<label class="label" for="password" v-html="t('login.password')"></label>
			<div class="control has-icons-left">
				<input class="input" type="password" v-model="password" ref="password" id="password" :disabled="app.loading">
				<span class="icon is-small is-left"><i class="ri-lock-password-fill"></i></span>
			</div>
			<p class="help" v-if="app.schema.enableRecovery">
				<a href :disabled="app.loading" @click="app.goto('recovery')" onclick="return false" v-html="t('login.goto.recovery')"></a>
			</p>
		</div>

		<div class="field">
			<div class="control">
				<button type="submit" class="button is-link" :disabled="disable" :class="{'is-loading': app.loading}" v-html="t('login.submit.login')"></button>
				<button type="button" class="button" v-if="app.schema.enableSignup" :disabled="app.loading" @click="app.goto('signup')" v-html="t('login.goto.signup')"></button>
			</div>
		</div>
	</form>
</template>
<script>
	export default {
		data() {
			return {
				app: window.peopledApp,
				username: "",
				password: "",
			};
		},
		methods: {
			t(key, ...args) {
				return this.app.t(key, ...args);
			},

			submit() {
				window.event.preventDefault();
				if (this.app.loading || this.disable) return;
				this.app.dialog = null;
				this.app.loading = true;
				this.login();
			},

			async login() {
				let res, body;
				try {
					res = await fetch(window.peopledEndpoint + "/authentication", {
						method: "POST",
						headers: {
							"Accept": "application/json",
							"Content-Type": "application/json",
						},
						body: JSON.stringify({ "username": this.username.trim(), "password": this.password }),
					});
					body = await res.json();
				} catch (err) {
					console.error(err);
					this.app.dialog = { type: "danger", context: "networkError" };
					this.app.top();
					return;
				}
				if (!res.ok) {
					setTimeout(() => {
						this.app.dialog = { type: "danger", "context": "login" + res.status };
						this.app.loading = false;
						this.$nextTick(() => this.$refs.username.select());
						this.app.top();
					}, 150);
				} else {
					window.peopledToken =  body.token;
					try {
						await this.app.updateSchema();
						await this.app.initialize();
						this.app.goto("tab-0");
					} catch (err) {}
				}
			},

		},
		computed: {
			disable() {
				return this.username == "" || this.password == "";
			},
		},
	}
</script>
