<template>
    <div class="field">
        <label class="label" :for="'field.' + schema.name" v-html="schema.options.title[app.language] || schema.options.title['']"></label>
        <div class="control">
            <button type="button" class="button" :id="'field.' + schema.name" style="width: 150px; height: 150px; overflow: hidden; padding: 0;">
                <img :src="value" style="width: 100%; height: 100%;">
            </button>
            <avatar-cropper
                    :upload-handler="upload"
                    @error="onerror"
                    :trigger="'#' + 'field\\.' + schema.name"
                    output-mime="image/jpeg"
                    :output-options="{ width: 256, height: 256 }"
                    :labels="{ submit: t('resource.pictureSubmit'), cancel: t('resource.pictureCancel') }" />
        </div>
        <p class="help">
            <span v-if="error" class="has-text-danger">{{ error }}<!-- v-html="t('resource.required')">--></span>
            <slot></slot>
        </p>
    </div>
</template>

<script>
    import AvatarCropper from "vue-avatar-cropper";
    export default {
        props: ["schema", "value", "error"],
        components: { AvatarCropper },
        data() {
            return {
                pictureError: null,
                app: window.peopledApp
            }
        },
        methods: {
            t(key, ...args) {
                return this.app.t(key, ...args);
            },
            upload(data) {
                this.$emit("input", data.getCroppedCanvas().toDataURL("image/jpeg", 0.9));
                this.pictureError = null;
            },
            onerror(err) {
                console.error(err);
                this.pictureError = err.message;
            },
        },
    }
</script>
