<template>
    <main ref="main">
        <div class="is-only is-flex" style="align-items: center; justify-content: center; height: 128px"
             v-if="loading === 'full' || (page === null && dialog === null)">
            <i class="ri-loader-4-fill ri-3x is-spinning"></i>
        </div>

        <div class="tabs" v-if="currentTab !== null" style="margin-left: -15px; margin-right: -15px;">
            <ul style="padding-left: 15px; padding-right: 15px;">
                <li v-for="(tab, i) in schema.tabs" :class="{'is-active': page === 'tab-' + i}" :style="{'margin-left': tab.type === 'logout' && i === schema.tabs.length - 1 ? 'auto' : '0'}">
                    <a tabindex="0" @click="goto('tab-' + i)">
                        <span v-if="tab.icon" class="icon is-small"><i v-if="tab.icon.indexOf('.') < 0" :class="[tab.icon]" aria-hidden="true"></i><img v-else :src="tab.icon"></span>
                        <span v-html="tab.name[language] || tab.name['']"></span>
                    </a>
                </li>
            </ul>
        </div>
        <h1 v-else-if="page !== null" class="title" style="align-items: center" v-html="t('title.' + page)"></h1>

        <div v-if="dialog" :class="['notification', 'peopled-dialog', 'is-' + dialog.type]">
            <button v-if="!dialog.permanent" class="delete" @click="dialog = null"></button>
            <strong v-html="t('dialog.' + dialog.type + '.title', { context: dialog.context || (dialog.message ? 'message' : null), ...dialog })"></strong><br>
            <small v-html="t('dialog.' + dialog.type + '.body', { context: dialog.context || (dialog.message ? 'message' : null), ...dialog })"></small>
        </div>

        <peopled-login v-if="page === 'login'" :class="{'is-loading': loading}" ref="login"></peopled-login>
        <peopled-signup v-if="page === 'signup'" :class="{'is-loading': loading}" ref="signup"></peopled-signup>
        <peopled-recovery v-if="page === 'recovery'" :class="{'is-loading': loading}" ref="recovery"></peopled-recovery>
        <peopled-tab v-if="currentTab !== null" v-for="(tab, i) in (schema.tabs || [])" v-show="currentTab === i" :key="i" :tab="tab" ref="tabs"></peopled-tab>
    </main>
</template>
<script>
    import "remixicon/fonts/remixicon.css";
    import "bulma/css/bulma.css";

    import PeopledLogin from "./login.vue";
    import PeopledSignup from "./signup.vue";
    import PeopledRecovery from "./recovery.vue";
    import PeopledTab from "./tab.vue";

    export default {
        data() {
            this.$nextTick(async () => {
                if (window.parent === window) {
                    window.document.documentElement.classList.add("standalone");
                }
                try {
                    await this.updateSchema();
                    await this.initialize();
                } catch (err) {
                }
            });
            return {
                loading: true,
                dialog: null, // object with required properties "type" (danger, warning, success or info) and "context" (see the translation files), as well as additional parameters for the translation of title & body, or "message" for a generic information.
                page: null,
                user: null,
                token: null,
                schema: {},
                language: "en",
            };
        },
        computed: {
            currentTab() {
                if (!this.page) return null;
                if (this.page.indexOf("tab-") !== 0) return null;
                return parseInt(this.page.substr(4));
            },
        },
        watch: {
            currentTab(after, before) {
                console.log(before, "=>", after)
                if (before !== null && this.$refs.tabs && this.$refs.tabs[before]) {
                    this.$refs.tabs[before].$emit("hide");
                }
                if (after !== null && this.$refs.tabs && this.$refs.tabs[after]) {
                    this.$refs.tabs[after].$emit("show");
                }
            },
        },
        methods: {
            t(key, ...args) {
                return window.i18n(key, ...args);
            },
            goto(page) {
                this.dialog = null;
                this.loading = null;
                this.token = null;
                this.page = page;
                this.top();
            },
            top() {
                window.scrollTo(0, 0);
                window.parent.postMessage({ peopledScrollToTop: true }, "*");
            },
            async updateSchema() {
                this.loading = true;
                this.page = null;
                let res, body;
                try {
                    res = await fetch(window.peopledEndpoint + "/schema", {
                        method: "GET",
                        headers: {Authorization: window.peopledToken},
                    });
                    body = await res.json();
                } catch (err) {
                    console.error(err);
                    this.dialog = {type: "danger", context: "networkError"};
                    throw null;
                }
                if (!res.ok) {
                    this.dialog = {
                        type: "danger",
                        context: "schemaError",
                        html: res.status + " " + res.statusText + (body ? "<br><pre style='color: inherit; padding: 0; background: transparent;'>" + JSON.stringify(body, undefined, 2) + "</pre>" : ""),
                        permanent: true
                    };
                    this.page = null;
                    throw null;
                }
                this.schema = body;
                this.loading = false;
            },
            async initialize() {
                if (window.location.hash.startsWith("#/signup/")) {
                    this.token = window.location.hash.substr("#/signup/".length);
                    history.replaceState(null, "", " "); // remove location hash
                    window.parent.postMessage({ peopledRemoveHash: true }, "*");

                    let res, body;
                    try {
                        res = await fetch(window.peopledEndpoint + "/signup/" + this.token, {method: "POST"});
                        body = await res.json();
                    } catch (err) {
                        console.error(err);
                        this.dialog = {type: "danger", context: "networkError"};
                        return;
                    }
                    if (!res.ok) {
                        this.dialog = {type: "danger", context: "signup" + res.status};
                        this.page = "login";
                        this.loading = false;
                    } else {
                        this.token = null;
                        this.page = "login";
                        this.loading = false;
                        if (body.success && body.isAdminConfirmation === true) this.dialog = {type: "success", context: "body_signupAdminConfirmationSuccess"};
                        else if (body.success && this.schema.requireAdminConfirmation) this.dialog = {type: "warning", context: "signupAwaitAdminConfirmation"};
                        else if (body.success) this.dialog = {type: "success", context: "signupSuccess"};
                        else this.dialog = {type: "warning", message: body.error};
                    }
                    return;
                }
                if (window.location.hash.startsWith("#/recovery/")) {
                    this.token = window.location.hash.substr("#/recovery/".length);
                    this.user = null;
                    this.loading = false;
                    this.page = "recovery";
                    return;
                }
                if (window.location.hash === "#/signup") {
                    history.replaceState(null, "", " "); // remove location hash
                    window.parent.postMessage({ peopledRemoveHash: true }, "*");
                    this.user = null;
                    this.loading = false;
                    this.page = "signup";
                    return;
                }
                try {
                    const res = await fetch(window.peopledEndpoint + "/user", {
                        headers: {Authorization: window.peopledToken},
                    });
                    if (!res.ok) throw null;
                    const body = await res.json();
                    if (body.error || !body.username) throw null;
                    this.user = body;
                    this.page = "tab-0";
                    this.loading = false;
                } catch (err) {
                    this.user = null;
                    this.page = "login";
                    this.loading = false;
                }
            },
            can(allowedViews, mustContain) {
                if (!allowedViews || !this.schema || !this.schema.viewpoints) return false;
                for (let i = 0; i < allowedViews.length; i++) {
                    let requiredViews = allowedViews[i].split("+");
                    let allowed = requiredViews.length > 0;
                    for (let j = 0; j < requiredViews.length; j++) {
                        if (this.schema.viewpoints.indexOf(requiredViews[j]) < 0 && requiredViews[j] !== "selfservice") {
                            allowed = false;
                            break;
                        }
                    }
                    if (allowed && (!mustContain || requiredViews.indexOf(mustContain) >= 0)) return true;
                }
                return false;
            },
        },
        components: {
            "peopled-login": PeopledLogin,
            "peopled-signup": PeopledSignup,
            "peopled-recovery": PeopledRecovery,
            "peopled-tab": PeopledTab,
        },
    }
</script>
<style>
    html {
        min-width: 0;
        overflow: hidden;
    }

    main { margin: 2px; } /* Fix highlighted input fields in the iFrame */

    .standalone { overflow-y: scroll; }
    .standalone body { padding: 1.5rem; }
    .standalone main { margin: 0 auto; max-width: 60rem; }
    @media screen and (min-width: 70rem) { .standalone main { margin: 5rem auto; } }

    @media screen and (max-width: 40rem) {
        main > .tabs a {
            flex-direction: column;
        }

        main > .tabs a > .icon {
            font-size: 1.8em;
            width: 1.8rem;
            height: 1.8rem;
            margin-right: 0 !important;
        }

        main > .tabs a > .icon + span {
            font-size: 0.8rem;
        }
    }

    .help [class^="ri-"], .help [class*=" ri-"] {
        margin-top: -0.15em;
        display: inline-block;
        transform: translateY(0.15em);
    }

    .is-only ~ * {
        display: none !important;
    }

    .is-spinning {
        animation: spin 0.8s infinite linear;
    }

    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }

    .field > .control > .button {
        margin-bottom: .75rem;
    }

    .peopled-dialog > strong:empty, .peopled-dialog > strong:empty + br {
        display: none;
    }

    .control.has-icons-left.select select { padding-left: 2.5em; }
    .control.has-icons-left select:focus ~ .icon { color: #4a4a4a; }
</style>
