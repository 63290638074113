import Vue from "vue";
import App from "./app.vue";

import i18next from "i18next";
import translationEN from "./i18n/en.json";
import translationDE from "./i18n/de.json";

Vue.config.productionTip = false;
Vue.config.devtools = true;

(async function () {

    window.peopledEndpoint = "/api"; // without trailing slash!
    Object.defineProperty(window, "peopledToken", {
        get: () => "Bearer " + window.sessionStorage.getItem("peopledToken"),
        set: v => window.sessionStorage.setItem("peopledToken", v.replace(/^Bearer /, "")),
    });

    window.peopledScrollHeight = 0;
    setInterval(() => {
        if (!window.parent || document.body.scrollHeight === window.peopledScrollHeight) return;
        window.parent.postMessage({ peopledScrollHeight: document.body.scrollHeight }, "*");
        window.peopledScrollHeight = document.body.scrollHeight;
    }, 50);

    const language = window.navigator.language.indexOf("de") === 0 ? "de" : "en";
    window.i18n = await i18next.init({
        lng: language,
        debug: true,
        resources: {
            en: {
                translation: translationEN,
            },
            de: {
                translation: translationDE,
            },
        }
    });

    window.peopledApp = new Vue({
        el: "#app",
        render: h => h(App),
    }).$children[0];
    window.peopledApp.language = language;
})();
