<template>
    <div>Hello World! I'm a resource type!</div>
</template>

<script>
    export default {
        data() {
            return {app: window.peopledApp};
        }
    }
</script>
