<template>
    <form @submit="submit">
        <div class="field">
            <label class="label" for="username" v-html="t('login.username')"></label>
            <div class="control has-icons-left">
                <input class="input" type="text" autocomplete="nickname" autocorrect="off" spellcheck="false" v-model="username" ref="username" id="username" :disabled="app.loading" :class="{'is-danger': (usernameIsInvalid || availability[username] === false), 'is-success': availability[username] === true}">
                <span class="icon is-small is-left"><i class="ri-user-fill"></i></span>
            </div>
            <p class="help">
                <span class="has-text-danger" v-if="usernameIsInvalid" v-html="t('login.usernameStatus.invalid')"></span>
                <span class="has-text-danger" v-else-if="availability[username] === false"><i class="ri-close-fill ri-help"></i> <span v-html="t('login.usernameStatus.taken')"></span></span>
                <span class="has-text-success" v-else-if="availability[username] === true"><i class="ri-check-fill ri-help"></i> <span v-html="t('login.usernameStatus.available')"></span></span>
                <span class="has-text-grey-light" v-else><i class="ri-loader-4-fill is-spinning ri-help"></i> <span v-html="t('login.usernameStatus.checking')"></span></span>
            </p>
        </div>

        <div class="field">
            <label class="label" for="name" v-html="t('login.name')"></label>
            <div class="control has-icons-left">
                <input class="input" type="text" autocomplete="fullname" autocorrect="off" spellcheck="false" v-model="name" ref="name" id="name" :disabled="app.loading" :class="{'is-danger': !usernameIsInvalid && availability[username] !== false && nameIsInvalid}">
                <span class="icon is-small is-left"><i class="ri-user-fill"></i></span>
            </div>
            <p class="help" :class="{'is-danger': !usernameIsInvalid && availability[username] !== false}" v-if="nameIsInvalid" v-html="t('login.nameStatus.invalid')"></p>
        </div>

        <div class="field">
            <label class="label" for="email" v-html="t('login.email')"></label>
            <div class="control has-icons-left">
                <input class="input" type="email" autocomplete="email" autocorrect="off" autocapitalize="off" v-model="email" ref="email" id="email" :disabled="app.loading" :class="{'is-danger': !usernameIsInvalid && availability[username] !== false && !nameIsInvalid && (emailIsInvalid || availability[email] === false)}">
                <span class="icon is-small is-left"><i class="ri-mail-fill"></i></span>
            </div>
            <p class="help" v-if="emailIsInvalid || availability[email] !== true">
                <span :class="{'has-text-danger': !usernameIsInvalid && availability[username] !== false && !nameIsInvalid}" v-if="emailIsInvalid" v-html="t('login.emailStatus.invalid')"></span>
                <span :class="{'has-text-danger': !usernameIsInvalid && availability[username] !== false && !nameIsInvalid}" v-else-if="availability[email] === false"><i class="ri-close-fill ri-help"></i> <span v-html="t('login.emailStatus.taken')"></span></span>
            </p>
        </div>

        <div class="field">
            <label class="label" for="password" v-html="t('login.password')"></label>
            <div class="control has-icons-left">
                <input class="input" type="password" v-model="password" ref="password" id="password" :disabled="app.loading" :class="{'is-danger': !usernameIsInvalid && availability[username] !== false && !nameIsInvalid && !emailIsInvalid && availability[email] !== false && passwordIsInvalid}">
                <span class="icon is-small is-left"><i class="ri-lock-password-fill"></i></span>
            </div>
            <p class="help" :class="{'is-danger': !usernameIsInvalid && availability[username] !== false && !nameIsInvalid && !emailIsInvalid && availability[email] === true}" v-if="passwordIsInvalid" v-html="passwordError"></p>
        </div>

        <div class="field" v-for="what in app.schema.requireAccept">
            <label class="checkbox">
                <input type="checkbox" v-model="accept[what]" :disabled="app.loading">
                <span :class="{'has-text-danger': !usernameIsInvalid && availability[username] !== false && !nameIsInvalid && !emailIsInvalid && availability[email] !== false && !passwordIsInvalid && !accept[what]}" v-html="t('login.accept.' + what, { link: app.schema.links[what] })"></span>
            </label>
        </div>

        <div class="field captcha" v-if="captcha.id">
            <label class="label is-marginless" for="captcha" v-html="t('login.captcha')"></label>
            <p class="help is-marginless" v-html="captchaHelp"></p>
            <a class="button is-static is-loading" v-if="captcha.loading"></a>
            <a class="button is-static is-danger" v-else-if="captcha.error" v-html="captcha.error"></a>
            <a class="button is-static is-paddingless" v-else><img :src="captcha.data"></a>
            <div class="field has-addons">
                <p class="control">
                    <input class="input" type="text" v-model="captcha.response" id="captcha" ref="captcha">
                </p>
                <p class="control">
                    <button type="button" class="button" :disabled="captcha.loading" @click="getCaptcha(true)" ref="captchaReload"><i class="ri-refresh-line"></i></button>
                </p>
            </div>
        </div>

        <div class="field">
            <div class="control">
                <button type="submit" class="button is-link" :disabled="disable" :class="{'is-loading': app.loading}" v-html='t("login.submit.signup")'></button>
                <button type="button" class="button" :disabled="app.loading" onclick="return false" @click="app.goto('login')" v-html="t('login.goto.login')"></button>
            </div>
        </div>
    </form>
</template>

<script>
    const yxcvbn = require("yxcvbn");
    const zxcvbn = require("zxcvbn");
    const passwordRule = /[a-zA-Z] *[^a-zA-Z ]|[^a-zA-Z ] *[a-zA-Z]/;
    const usernameRule = /^[a-zA-Z0-9-_.]{3,32}$/;
    export default {
        data() {
            if (window.peopledApp.schema.enableSignupCaptcha) this.$nextTick(() => this.getCaptcha());
            return {
                app: window.peopledApp,
                username: "",
                password: "",
                email: "",
                name: "",
                captcha: {
                    id: "",
                    data: "",
                    response: "",
                    loading: false,
                    error: null,
                },
                accept: {},
                availability: {},
            }
        },
        methods: {
            t(key, ...args) {
                return this.app.t(key, ...args)
            },

            submit() {
                window.event.preventDefault();
                if (this.app.loading || this.disable) return;
                this.app.dialog = null;
                this.app.loading = true;
                this.signup();
            },

            async signup() {
                let res, body;
                try {
                    res = await fetch(window.peopledEndpoint + "/signup", {
                        method: "POST",
                        headers: {
                            "Accept": "application/json",
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            "username": this.username.trim(),
                            "password": this.password,
                            "name": this.name.trim(),
                            "email": this.email,
                            "accept": this.accept,
                            "captcha": {
                                "id": this.captcha.id,
                                "response": this.captcha.response,
                            },
                        }),
                    });
                    body = await res.json();
                } catch (err) {
                    console.error(err);
                    this.app.dialog = { type: "danger", context: "networkError" };
                    this.app.loading = false;
                    this.getCaptcha();
                    this.app.top();
                    return;
                }
                if (!res.ok) {
                    setTimeout(() => {
                        this.app.dialog = { type: "danger", "context": "signup" + res.status };
                        if (res.status === 400 && body.error["captcha.response"] === "captcha is invalid") this.app.dialog = { type: "danger", "context": "signupCaptcha"};
                        this.app.loading = false;
                        this.getCaptcha();
                        this.app.top();
                        this.$nextTick(() => this.$refs.username.select());
                    }, 150);
                    return;
                }
                this.app.goto("login");
                if (this.app.schema.requireEmailConfirmation) this.app.dialog = { type: "warning", context: "signupRequest" };
                else if (this.app.schema.requireAdminConfirmation) this.app.dialog = { type: "warning", context: "signupAwaitAdminConfirmation" };
                else this.app.dialog = { type: "success", context: "signupSuccess" };
            },

            // check asynchronously if the username is already taken
            async checkUsername(username) {
                if (username && this.availability[username] === undefined) {
                    this.availability[username] = null;
                    let res, body;
                    try {
                        res = await fetch(window.peopledEndpoint + "/user/" + encodeURIComponent(username));
                        body = await res.json();
                    } catch (err) {
                        console.error(err);
                        delete this.availability[username];
                        setTimeout(() => this.checkUsername(username), 2500);
                        return false;
                    }
                    if (!res.ok) {
                        delete this.availability[username];
                        setTimeout(() => this.checkUsername(username), 2500);
                        return false;
                    }
                    setTimeout(() => {
                        this.availability[username] = !body.exists;
                        this.$forceUpdate();
                    }, 150)
                }
            },

            async getCaptcha(manual) {
                if (this.captcha.loading) return;
                this.captcha.loading = true;
                let start = Date.now();
                let res, body;
                try {
                    res = await fetch(window.peopledEndpoint + "/signup", {
                        method: "GET",
                        headers: {"Accept": "application/json"},
                    });
                    body = await res.json();
                } catch (err) {
                    this.captcha.error = this.t("login.captchaStatus.networkError");
                    this.captcha.loading = false;
                    return;
                }
                if (!res.ok) {
                    this.captcha.error = this.t("login.captchaStatus.unknownError");
                    this.captcha.loading = false;
                    return;
                }
                this.captcha.error = null;
                this.captcha.id = body.id;
                this.captcha.response = "";
                this.captcha.data = body.data;
                // Wait at least 1 second before displaying the captcha -> no rate limitations for the user
                setTimeout(() => {
                    this.captcha.loading = false;
                    if (manual) this.$refs.captcha.focus();
                }, Math.max(0, 1000 - (Date.now() - start)));
            },
        },
        computed: {
            usernameIsInvalid() {
                return !this.username.trim().match(usernameRule);
            },

            nameIsInvalid() {
                return !this.name.trim();
            },

            passwordIsInvalid() {
                return this.passwordError != null;
            },

            passwordError() {
                if (this.password.length < 8 || !this.password.match(passwordRule)) return this.t('login.passwordStatus.invalid');
                const result = (this.app.language === "de" ? yxcvbn : zxcvbn)(this.password);
                if (result.feedback.warning) return result.feedback.warning;
                if (result.score < 3) return this.t('login.passwordStatus.unsafe');
                return null;
            },

            emailIsInvalid() {
                return !this.email || (this.$refs.email && this.$refs.email.validationMessage) || !(this.email.indexOf("@") > 0 && this.email.indexOf("@") < this.email.lastIndexOf("."));
            },

            captchaHelp() {
                let v = this.t('login.captchaDescription');
                if (!this.usernameIsInvalid && this.availability[this.username] !== false && !this.nameIsInvalid && !this.emailIsInvalid && this.availability[this.email] !== false && !this.passwordIsInvalid && Object.keys(this.accept).filter(k => !this.accept[k]).length === 0 && !this.captcha.response.trim()) v += ' <span class="has-text-danger">' + this.t('login.captchaStatus.required') + '</span>';
                else if (!this.captcha.response.trim()) v += " " + this.t('login.captchaStatus.required');
                return v;
            },

            disable() {
                return !this.username || !this.password || this.usernameIsInvalid || this.emailIsInvalid || this.passwordIsInvalid || Object.keys(this.accept).filter(k => !this.accept[k]).length !== 0 || !this.captcha.response.trim();
            },
        },
        watch: {
            username() {
                if (this.username && !this.usernameIsInvalid) this.checkUsername(this.username);
            },
            email() {
                if (this.email && !this.emailIsInvalid) this.checkUsername(this.email);
            },
        }
    }
</script>

<style>
    .captcha {
        margin-bottom: 0;
    }

    .captcha > .button {
        height: 80px;
        width: 275px;
        margin-top: 0.5em;
        box-sizing: border-box;
        overflow: hidden;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: none;
    }

    .captcha > .field {
        width: 275px;
    }

    .captcha > .field > :first-child, .captcha > .field > :first-child > :first-child {
        border-top-left-radius: 0;
    }

    .captcha > .field > :last-child, .captcha > .field > :last-child > :last-child {
        border-top-right-radius: 0;
    }
</style>
